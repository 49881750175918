import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Search from "../../Search/Search";
import { defineMessages, FormattedDate, FormattedMessage, FormattedNumber, injectIntl, intlShape } from "react-intl";
import { speak } from "@wordpress/a11y";
import util from "util";
import _debounce from "lodash/debounce";
import { Table, Alert, Link } from "@yoast/ui-library";
import formatAmount from "../../../functions/currency";
import InvoicesDownloadContainer from "../../../containers/InvoicesDownload";
import * as styles from "./OrdersPageStyles.scss";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import { PageHeader } from "../../PageHeader";

const messages = defineMessages( {
	date: {
		id: "orders.overview.date",
		defaultMessage: "Date",
	},
	title: {
		id: "orders.overview.title",
		defaultMessage: "Orders",
	},
	orderNumber: {
		id: "orders.overview.orderNumber",
		defaultMessage: "Order",
	},
	items: {
		id: "orders.overview.items",
		defaultMessage: "Items",
	},
	total: {
		id: "orders.overview.total",
		defaultMessage: "Total",
	},
	status: {
		id: "orders.overview.status",
		defaultMessage: "Status",
	},
	searchLabel: {
		id: "search.label.orders",
		defaultMessage: "Search orders",
	},
	ordersPageLoaded: {
		id: "menu.account.orders.loaded",
		defaultMessage: "Account orders page loaded",
	},
	searchResults: {
		id: "ordersSearch.results",
		defaultMessage: "Number of orders found: %d",
	},
	infoOrdersOnlyProvisionedSubscriptions: {
		id: "orders.infoProvisionedNotShopify",
		defaultMessage: "You have bought one of our products through one of our provisioning partners and those are not managed within MyYoast.",
	},
	infoOrdersMixedProvisionedSubscriptions: {
		id: "orders.infoMixedProvisionedSubscriptions",
		defaultMessage: "You currently have Yoast SEO products as well as products by Shopify, Bluehost or WordPress.com. " +
			"There are no orders in MyYoast for products purchased from them.",
	},
} );

const debouncedSpeak = _debounce( speak, 1000 );

/**
 * Returns n Alert if certain conditions are met.
 *
 * @param {Boolean} isOnlyProvisionerSubscriptions Does user have only provisioned subscriptions?
 * @param {Boolean} hasMixedSubscriptions Does user have subscriptions mixed between Yoast and any of the provisioners?
 * @param {Object} intl Intl object.
 *
 * @returns {Boolean|false|ReactElement} An info alert or false if conditions are not met.
 */
const OrdersPageAlert = ( { isOnlyProvisionerSubscriptions, hasMixedSubscriptions, intl } ) => {
	return (
		<>
			{ ( isOnlyProvisionerSubscriptions ) && (
				<Alert type="info" className={ styles.ordersPageAlert }>
					{ intl.formatMessage( messages.infoOrdersOnlyProvisionedSubscriptions ) }
				</Alert>
			) }

			{ ( hasMixedSubscriptions ) && (
				<Alert type="info" className={ styles.ordersPageAlert }>
					{ intl.formatMessage( messages.infoOrdersMixedProvisionedSubscriptions ) }
				</Alert>
			) }
		</>
	);
};

OrdersPageAlert.propTypes = {
	isOnlyProvisionerSubscriptions: PropTypes.bool,
	hasMixedSubscriptions: PropTypes.bool,
	intl: intlShape.isRequired,
};

/**
 * A function that returns the Order Page component, containing a search bar and the orders table.
 *
 * @param {Object} props The props.
 *
 * @returns {ReactElement} The component that contains the search bar and the order page.
 */
const OrdersPage = props => {
	/**
	 * Speaks the next results message.
	 *
	 * @returns {void}
	 */
	const speakSearchResultsMessage = () => {
		if ( props.query.length > 0 ) {
			const message = util.format( props.intl.formatMessage( messages.searchResults ), props.transactions.length );

			debouncedSpeak( message, "assertive" );
		}
	};

	useEffect( () => {
		props.loadData();

		// Announce navigation to assistive technologies.
		const message = props.intl.formatMessage( messages.ordersPageLoaded );
		speak( message );
	}, [] );

	const isFirstRun = useRef( true );
	useEffect( () => {
		if ( isFirstRun.current ) {
			isFirstRun.current = false;
			return;
		}
		/*
		 * While typing or pasting in the search field, `componentWillReceiveProps()`
		 * continously passes a new `query` props. We use this at our advantage
		 * to debounce the call to `speak()`.
		 * Note: remember for <input> and <textarea>, React `onChange` behaves
		 * like the DOM's built-in oninput event handler.
		 */
		speakSearchResultsMessage();
	}, [ props.query ] );

	return (
		<>
			<div className={ styles.ordersPageContentWrapper }>
				<PageHeader title={ messages.title } />

				<OrdersPageAlert { ...props } />

				<div className={ styles.search }>
					{ ( props.transactions.length > 0 || props.query ) &&
						<Search
							id="search"
							searchLabel={ props.intl.formatMessage( messages.searchLabel ) }
							descriptionId="search-description"
							onChange={ props.onSearchChange }
							query={ props.query }
						/>
					}
				</div>

				{ ( props.query.length > 0 && props.transactions.length === 0 ) &&
					<p className={ styles.noResults }>
						<FormattedMessage
							id="orders.search.noResults"
							key="orders.search.noResults"
							defaultMessage={ "We could not find any orders matching { query }." }
							values={ { query: <strong>{ props.query }</strong> } }
						/>
					</p> }

				{ ( props.query.length <= 0 && props.transactions.length === 0 ) &&
					<p className={ styles.noResults }>
						<FormattedMessage
							id="orders.noOrders.manage"
							key="orders.noOrders.manage"
							defaultMessage="It looks like you didn't order anything yet! To get your first product, visit our { link }."
							values={ {
								link: <Link
									className={ styles.ordersPageLink } target="_blank"
									href="https://yoast.com/shop"
								>
									<span>shop</span>
									<ArrowTopRightOnSquareIcon className={ styles.ordersPageLinkIcon } />
								</Link>,
							} }
						/>
					</p> }
			</div>

			{ props.transactions.length > 0 &&
				<Table>
					<Table.Head className={ styles.tableHead }>
						<Table.Row>
							<Table.Header>
								<FormattedMessage { ...messages.date } />
							</Table.Header>
							<Table.Header>
								<FormattedMessage { ...messages.orderNumber } />
							</Table.Header>
							<Table.Header>
								<FormattedMessage { ...messages.items } />
							</Table.Header>
							<Table.Header>
								<FormattedMessage { ...messages.total } />
							</Table.Header>
							<Table.Header>
								<FormattedMessage { ...messages.status } />
							</Table.Header>
							<Table.Header>
								<span className="yst-sr-only">Actions</span>
							</Table.Header>
						</Table.Row>
					</Table.Head>
					<Table.Body className={ styles.tableBody }>
						{ props.transactions.map( transaction => (
							<Table.Row key={ transaction.id }>
								<Table.Cell data-label={ messages.date.defaultMessage }>
									<FormattedDate
										value={ transaction.date } day="numeric" month="long"
										year="numeric"
									/>
								</Table.Cell>
								<Table.Cell data-label={ messages.orderNumber.defaultMessage }>
									{ transaction.invoiceNumber }
								</Table.Cell>
								<Table.Cell data-label={ messages.items.defaultMessage }>
									{ transaction.items.map( item => (
										<p key={ item.id }>
											{ item.quantity }&times; { item.productName }
										</p>
									) ) }
								</Table.Cell>
								<Table.Cell data-label={ messages.total.defaultMessage }>
									<FormattedNumber
										value={ formatAmount( transaction.totalAmount ) }
										currency={ transaction.currency } style="currency"
									/>
								</Table.Cell>
								<Table.Cell
									className={ styles.transactionStatusCell }
									data-label={ messages.status.defaultMessage }
								>
									{ transaction.status }
								</Table.Cell>
								<Table.Cell data-label="Actions">
									<InvoicesDownloadContainer resourceId={ transaction.id } type={ transaction.type } />
								</Table.Cell>
							</Table.Row>
						) ) }
					</Table.Body>
				</Table> }
		</>
	);
};

export default injectIntl( OrdersPage );

OrdersPage.propTypes = {
	onSearchChange: PropTypes.func.isRequired,
	transactions: PropTypes.array,
	intl: intlShape.isRequired,
	query: PropTypes.string,
	loadData: PropTypes.func,
	isOnlyProvisionerSubscriptions: PropTypes.bool,
	hasMixedSubscriptions: PropTypes.bool.isRequired,
};

OrdersPage.defaultProps = {
	transactions: [],
	query: "",
	loadData: () => {
	},
	isOnlyProvisionerSubscriptions: false,
};
