// External dependencies.
import PropTypes from "prop-types";
import React from "react";

// Internal dependencies.
import logo from "../../images/logoColor.svg";
import Link from "../Link.js";

import * as styles from "./styles.scss";

/**
 * @param {Object} props Component props.
 * @param {String} props.size The width of the prop.
 * @param {String} props.alt The alternative text to show for the logo.
 * @returns {ReactElement} A react component describing the logo component.
 * @constructor
 */
export function Logo( props ) {
  switch ( props.context ) {
    case "header":
      return (
        <Link to="/" className={ styles.logo }>
          <img
            src={ logo } width="200" height="58" decoding="async" fetchpriority="high" size={ props.size }
            alt={ props.alt }
            style={{ size: props.size? props.size : '' }}
          />
        </Link>
      );
    case "sidebar":
      return (
        <Link to="/" className={ styles.logo }>
          <img
            src={ logo } width="200" height="58" decoding="async" fetchpriority="high" size={ props.size }
            alt={ props.alt }
            style={{ size: props.size? props.size : '' }}
          />
        </Link>
      );
  }
}

Logo.propTypes = {
  size: PropTypes.string,
  alt: PropTypes.string,
  context: PropTypes.string.isRequired,
};

Logo.defaultProps = {
  size: "200px",
  alt: "Home page",
};
