import PropTypes from "prop-types";
import React, { useEffect, useState, useCallback, useContext } from "react";
import { FormattedMessage, FormattedNumber, injectIntl, intlShape } from "react-intl";
import { REFUND_POLICY_URL, SWITCH_BILLING_FAQ_URL, TERMS_OF_SERVICE_URL } from "../../../config/constants";
import useRequest from "../../../reactHooks/useRequest";
import formatAmount from "../../../functions/currency";
import { ArrowSmallRightIcon, CheckCircleIcon } from "@heroicons/react/24/solid";
import { noop } from "shared-frontend/functions/noop";
import { doRequest, prepareInternalRequest } from "shared-frontend/functions/api";
import { PageSection } from "../../PageSection";
import { Button, Link, Alert, Toggle, SelectField, Tooltip, Spinner } from "@yoast/ui-library";

import { messages } from "./messages";

import * as styles from "./styles.scss";
import { SubscriptionDetailContext } from "../../../context/SubscriptionDetailContext";

/**
 * A tool that lets users choose from a list of eligible products to upgrade their subscription to.
 * The upgrade trades in time for a more expensive product.
 *
 * @param {Object} props The props as described by proptypes.
 *
 * @returns {JSX.Element} The SubscriptionUpgrade tool.
 */
export const SubscriptionUpgradeTool = ( props ) => {
	const { subscriptionId, request: subscriptionRequest } = useContext( SubscriptionDetailContext );
	const [ selectedSwitchOptionIndex, setSelectedSwitchOptionIndex ] = useState( 0 );
	const [ upgrading, setUpgrading ] = useState( false );
	const [ upgradeSuccess, setUpgradeSuccess ] = useState( false );
	const [ lastUpgradeError, setLastUpgradeError ] = useState( null );
	const [ refundPolicyChecked, setRefundPolicyChecked ] = useState( false );
	const [ termsPolicyChecked, setTermsPolicyChecked ] = useState( false );
	const [ showCheckboxError, setShowCheckboxError ] = useState( false );

	const {
		data: switchOptions,
		isLoading,
		error: getSwitchOptionsError,
		reload,
	} = useRequest( `Subscriptions/${ subscriptionId }/product-switch-options` );

	const selectedSwitchOption = switchOptions[ selectedSwitchOptionIndex ] || null;

	useEffect( () => {
		if ( upgrading ) {
			setLastUpgradeError( null );

			doRequest(
				prepareInternalRequest(
					`Subscriptions/${ subscriptionId }/switch-product`,
					"POST",
					{ productId: selectedSwitchOption.newProduct.id },
				),
			)
				.then( () => {
					setUpgradeSuccess( true );
				} )
				.catch( ( error ) => {
					console.error( error );
					setLastUpgradeError( { message: props.intl.formatMessage( messages.errorOccurredWhileUpdating ) } );
				} )
				.finally( () => {
					props.reloadSubscriptionData();
					setUpgrading( false );
					subscriptionRequest.reload();
				} );
		}
	}, [ upgrading ] );

	useEffect( () => {
		if ( termsPolicyChecked && refundPolicyChecked ) {
			setShowCheckboxError( false );
		}
	}, [ termsPolicyChecked, refundPolicyChecked ] );

	const handleSubmitButtonClick = useCallback(
		() => {
			if ( ! termsPolicyChecked || ! refundPolicyChecked ) {
				setShowCheckboxError( true );
			}

			if ( termsPolicyChecked && refundPolicyChecked ) {
				setUpgrading( true );
				setShowCheckboxError( false );
			}
		},
		[ upgrading, termsPolicyChecked, refundPolicyChecked ],
	);

	const upgradeBillingFaqLink = ( <Link href={ SWITCH_BILLING_FAQ_URL } target="_blank">
		<FormattedMessage { ...messages.updatedPaymentDetailsLink } />
	</Link> );
	const upgradeRefundPolicyLink = <Link href={ REFUND_POLICY_URL } target="_blank">
		<FormattedMessage { ...messages.refundPolicyLabelLink } />
	</Link>;
	const upgradeBillingTermsLink = <Link href={ TERMS_OF_SERVICE_URL } target="_blank">
		<FormattedMessage { ...messages.termsPolicyLabelLink } />
	</Link>;

	if ( isLoading ) {
		return <Spinner />;
	}

	if ( getSwitchOptionsError ) {
		return <>
			<p><FormattedMessage { ...messages.errorOccurredWhileFetching } /></p>
			<div className={ styles.actionButtonsContainer }>
				{ props.onCancel !== noop &&
				<Button variant="secondary" onClick={ props.onCancel }>
					<FormattedMessage { ...messages.cancel } />
				</Button>
				}
				<Button variant="secondary" onClick={ reload }>
					<FormattedMessage { ...messages.tryAgain } />
				</Button>
			</div>
		</>;
	}

	if ( ! switchOptions || switchOptions.length < 1 ) {
		return <>
			<p><FormattedMessage { ...messages.noValidUpgrades } /></p>
			<div className={ styles.actionButtonsContainer }>
				{ props.onCancel !== noop &&
				<Button variant="secondary" onClick={ props.onCancel }>
					<FormattedMessage { ...messages.close } />
				</Button>
				}
			</div>
		</>;
	}

	if ( upgradeSuccess ) {
		return <>
			<div>
				<p>
					<FormattedMessage
						{ ...messages.upgradeSuccess }
						values={ { newProduct: <strong>{ selectedSwitchOption.newProduct.name }</strong> } }
					/>
				</p>
				<p><FormattedMessage { ...messages.getStarted } /></p>
			</div>
			<div className={ styles.actionButtonsContainer }>
				{ props.onCancel !== noop &&
				<Button variant="secondary" onClick={ props.onCancel }>
					<FormattedMessage { ...messages.close } />
				</Button>
				}
				<Link href="/downloads">
					<Button>
						<FormattedMessage { ...messages.install } />
					</Button>
				</Link>
			</div>
		</>;
	}

	/* eslint-disable no-undefined */
	const currentNextBilling = new Date( selectedSwitchOption.currentSubscription.currentNextBilling ).toLocaleDateString(
		"en-US",
		{ dateStyle: "long" },
	);
	const newNextBilling = new Date( selectedSwitchOption.newNextBilling ).toLocaleDateString(
		"en-US",
		{ dateStyle: "long" },
	);
	/* eslint-enable */

	const currentProductCurrency = selectedSwitchOption.currentSubscription.product.currency;
	const newProductCurrency = selectedSwitchOption.newProduct.currency;

	/* eslint-disable no-inline-comments,react/jsx-no-bind */
	return (
		<>
			{ lastUpgradeError && <Alert variant="error">{ lastUpgradeError.message } </Alert> }
			<PageSection title={ messages.currentSubscription } full={ true }>
				<div className={ styles.currentSubscription }>{ selectedSwitchOption.currentSubscription.product.name }</div>

				<SelectField
					id="upgrade-to"
					name="upgradeTo"
					label={ props.intl.formatMessage( messages.upgradeTo ) }
					value={ selectedSwitchOptionIndex.toString() }
					disabled={ upgrading }
					onChange={ ( value ) => setSelectedSwitchOptionIndex( value ) }
					options={
						switchOptions.map(
							( switchOption, key ) => {
								return { value: key.toString(), label: switchOption.newProduct.name };
							},
						)
					}
				/>
			</PageSection>
			<PageSection title={ messages.whatIsIncluded } full={ true }>
				<div className={ styles.checkmarkList }>
					{
						selectedSwitchOption.newProduct.productGroups.map( ( productGroupName, key ) => (
							<div key={ `pg-${ key }` }>
								<CheckCircleIcon />
								{ productGroupName }
							</div>
						) )
					}
				</div>
			</PageSection>

			<PageSection title={ messages.updatedPaymentDetailsHeader } full={ true }>
				<p>
					<FormattedMessage
						{ ...messages.updatedPaymentDetails }
						values={ {
							percentage: selectedSwitchOption.estimatedBundleDiscountPercentage,
							link: upgradeBillingFaqLink,
						} }
					/>
				</p>
			</PageSection>

			<PageSection title={ messages.nextBilling } full={ true }>
				<div className={ styles.nextBilling }>
					<div aria-describedby="currentNextBillingTooltip">
						{ currentNextBilling }
						<Tooltip id="currentNextBillingTooltip" className={ styles.tooltip } position="right">
							{ props.intl.formatMessage( messages.currentNextBilling ) }
						</Tooltip>
					</div>
					<ArrowSmallRightIcon />
					<div aria-describedby="newNextBillingTooltip">
						{ newNextBilling }
						<Tooltip id="newNextBillingTooltip" className={ styles.tooltip } position="right">
							{ props.intl.formatMessage( messages.newNextBilling ) }
						</Tooltip>
					</div>
				</div>
			</PageSection>

			<PageSection title={ messages.nextRenewalPrice } full={ true }>
				<div className={ styles.nextRenewalPrice }>
					<div aria-describedby="currentNextRenewalPriceTooltip">
						<FormattedNumber
							value={ formatAmount( selectedSwitchOption.currentSubscription.estimatedRenewalPriceInCents ) }
							currency={ currentProductCurrency }
							style="currency"
						/> { currentProductCurrency }
						<Tooltip id="currentNextRenewalPriceTooltip" className={ styles.tooltip } position="right">
							{ props.intl.formatMessage( messages.currentPrice ) }
						</Tooltip>
					</div>
					<ArrowSmallRightIcon />
					<div aria-describedby="newNextRenewalPriceTooltip">
						<FormattedNumber
							value={ formatAmount( selectedSwitchOption.newEstimatedRenewalPriceInCents ) }
							currency={ newProductCurrency }
							style="currency"
						/>
						{ newProductCurrency }
						<Tooltip id="newNextRenewalPriceTooltip" className={ styles.tooltip } position="right">
							{ props.intl.formatMessage( messages.newPrice ) }
						</Tooltip>
					</div>
					<span className={ styles.newPriceSubtext }>
						<FormattedMessage
							{ ...messages.nextBillingSubtext }
							values={ { term: selectedSwitchOption.newProduct.billingTerm } }
						/>
					</span>
				</div>
			</PageSection>

			<div className={ styles.requiredCheckmarks }>
				<div className={ styles.toggleWrapper }>
					<Toggle
						id="switch-refund-policy"
						screenReaderLabel={ props.intl.formatMessage( messages.screenReaderRefundPolicyLabel ) }
						onChange={ value => setRefundPolicyChecked( value ) }
						checked={ refundPolicyChecked }
					/>
					<p>
						<FormattedMessage
							{ ...messages.refundPolicyLabel }
							values={ { link: upgradeRefundPolicyLink } }
						/>
					</p>
				</div>

				<div className={ styles.toggleWrapper }>
					<Toggle
						id="switch-terms-policy"
						screenReaderLabel={ props.intl.formatMessage( messages.screenReaderTermsPolicyLabel ) }
						onChange={ value => setTermsPolicyChecked( value ) }
						checked={ termsPolicyChecked }
					/>
					<p>
						<FormattedMessage
							{ ...messages.termsPolicyLabel }
							values={ { link: upgradeBillingTermsLink } }
						/>
					</p>
				</div>

				{
					showCheckboxError &&
					<Alert variant="error">
						<FormattedMessage { ...messages.checkboxesNotChecked } />
					</Alert>
				}
			</div>

			<div className={ styles.actionButtonsContainer }>
				{ props.onCancel !== noop &&
					<Button variant="secondary" onClick={ props.onCancel }>
						<FormattedMessage { ...messages.cancel } />
					</Button>
				}
				<Button
					variant="upsell"
					type="submit"
					onClick={ handleSubmitButtonClick }
					disabled={ upgrading }
				>
					{ upgrading
						? <FormattedMessage { ...messages.upgrading } />
						: <FormattedMessage { ...messages.confirm } />
					}
				</Button>
			</div>
		</>
	);
	/* eslint-enable */
};

SubscriptionUpgradeTool.propTypes = {
	intl: intlShape.isRequired,
	onCancel: PropTypes.func,
	onUpgradeSuccess: PropTypes.func,
	reloadSubscriptionData: PropTypes.func,
};

SubscriptionUpgradeTool.defaultProps = {
	onCancel: noop,
	onUpgradeSuccess: noop,
	reloadSubscriptionData: noop,
};

export default injectIntl( SubscriptionUpgradeTool );
