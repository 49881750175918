import React, { useEffect } from "react";
import { defineMessages, injectIntl, intlShape } from "react-intl";
import PropTypes from "prop-types";
import { getInvoiceUrl } from "shared-frontend/functions/api";
import { InvoicesModal } from "./InvoicesModal";
import MyYoastModal from "../../MyYoastModal";
import { ErrorPropTypeShape } from "../../../errors/ErrorDisplay";
import { Button } from "@yoast/ui-library";
import { ArrowDownTrayIcon, EyeIcon } from "@heroicons/react/24/outline";
import * as styles from "./InvoiceButtonAreaStyles.scss";
import { noop } from "../../../../../shared-frontend/functions/noop";

const messages = defineMessages( {
	invoice: {
		id: "orders.overview.invoice",
		defaultMessage: "Invoice",
	},
	invoiceLabel: {
		id: "orders.overview.invoice.label",
		defaultMessage: "Download invoice",
	},
	invoiceModal: {
		id: "orders.overview.invoiceModal",
		defaultMessage: "View invoices",
	},
	invoicesModalLabel: {
		id: "orders.overview.invoices.modal.label",
		defaultMessage: "View and download invoices",
	},
} );

/**
 * The InvoiceButtonArea component.
 * @param {object} props Properties.
 * @returns {JSXElement} The InvoiceButtonArea component.
 */
const InvoiceButtonArea = ( props ) => {
	const {
		      resourceId,
		      type,
		      intl,
		      hasMultipleInvoices,
		      onInvoicesClick,
		      onInvoicesClose,
		      invoiceModalProps,
		      invoices,
	      } = props;

	useEffect( () => {
		// Only load data if the state isn't already available.
		if ( invoices.length === 0 ) {
			props.loadData();
		}
	}, [] );

	// eslint-disable-next-line require-jsdoc
	const getModal = () => {
		const theModal = (
			<MyYoastModal
				onClose={ onInvoicesClose }
				isOpen={ invoiceModalProps.invoicesModalResourceId === resourceId }
				modalAriaLabel={ messages.invoicesModalLabel }
			>
				<InvoicesModal { ...props } />
			</MyYoastModal>
		);

		return invoiceModalProps.invoicesModalResourceId
			? theModal
			: null;
	};

	// eslint-disable-next-line require-jsdoc
	const getButton = () => {
		const invoiceURI          = getInvoiceUrl( type, resourceId );
		const invoiceModalMessage = intl.formatMessage( messages.invoiceModal );
		const invoiceLabel        = intl.formatMessage( messages.invoiceLabel );

		if ( hasMultipleInvoices ) {
			return (
				<Button
					as="a"
					variant="secondary"
					aria-label={ invoiceLabel }
					onClick={ () => {
						return onInvoicesClick( resourceId );
					} }
				>
					<EyeIcon className={ styles.downloadButtonIcon } />
					<span>{ invoiceModalMessage }</span>
				</Button>
			);
		}
		return (
			<Button
				as="a"
				href={ invoiceURI }
				variant="secondary"
				aria-label={ invoiceLabel }
			>
				<ArrowDownTrayIcon className={ styles.downloadButtonIcon } />
				<span>{ invoiceLabel }</span>
			</Button>
		);
	};

	return (
		<div className={ styles.downloadButtonsWrapper }>
			{ getButton() }
			{ getModal() }
		</div>
	);
};

export default injectIntl( InvoiceButtonArea );

InvoiceButtonArea.propTypes = {
	invoices: PropTypes.array.isRequired,
	resourceId: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	intl: intlShape.isRequired,
	hasMultipleInvoices: PropTypes.bool,
	onInvoicesClick: PropTypes.func.isRequired,
	onInvoicesClose: PropTypes.func.isRequired,
	invoiceModalProps: PropTypes.shape( {
		invoicesModalIsOpen: PropTypes.bool,
		invoicesModalResourceId: PropTypes.string,
		error: ErrorPropTypeShape,
	} ),
	loadData: PropTypes.func,
};

InvoiceButtonArea.defaultProps = {
	loadData: noop,
	hasMultipleInvoices: false,
	invoiceModalProps: {
		invoicesModalIsOpen: false,
		invoicesModalResourceId: "",
		error: null,
	},
};
